.overflow{
    overflow: scroll !important;
    height: 100% !important;
}

.padding{
    padding: 1rem !important;
}

.margin-top{
    margin-top: 1rem !important;
}

.margin-bottom{
    margin-bottom: 1rem !important;
}

.full-width{
    width: 100% !important;
}

span{
    color: red !important;
}

.form-label{
    text-align: left !important;
    width: 100% !important; 
}

.form-text{
    display: block;
    text-align: left !important;
    width: 100% !important;
}